<template>
    <div class="mw-100 p-2">
        <div class="section">
            <div class="card">
                <div class="card-body py-2 px-0">
                    <div class="row pb-1 px-3 align-items-center gap-1" v-if="this.hasTablePermission">
                        <p class="f-14 font-600 text-black mb-0">Status</p>
                        <button
                            type="button"
                            class="btn btn-sm"
                            :class="status === null ? 'btn-primary' : 'btn-outline-secondary'"
                            @click="handleChangeStatus(null)"
                        >
                            Semua
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm"
                            :class="status === 'BOOKED' ? 'btn-primary' : 'btn-outline-secondary'"
                            @click="handleChangeStatus('BOOKED')"
                        >
                            Booked
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm"
                            :class="status === 'CONFIRMED' ? 'btn-primary' : 'btn-outline-secondary'"
                            @click="handleChangeStatus('CONFIRMED')"
                        >
                            Confirmed
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm"
                            :class="status === 'CHECKED_IN' ? 'btn-primary' : 'btn-outline-secondary'"
                            @click="handleChangeStatus('CHECKED_IN')"
                        >
                            Checked In
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm"
                            :class="status === 'ON_TREATMENT' ? 'btn-primary' : 'btn-outline-secondary'"
                            @click="handleChangeStatus('ON_TREATMENT')"
                        >
                            On Treatment
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm"
                            :class="status === 'COMPLETED' ? 'btn-primary' : 'btn-outline-secondary'"
                            @click="handleChangeStatus('COMPLETED')"
                        >
                            Completed
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm"
                            :class="status === 'CANCELED' ? 'btn-primary' : 'btn-outline-secondary'"
                            @click="handleChangeStatus('CANCELED')"
                        >
                            Canceled
                        </button>
                        <p class="f-14 font-600 text-blue pointer mb-0"
                            @click="resetFilter">
                            Reset Filter
                        </p>
                    </div>
                    <div class="row pb-1 px-3">
                        <div class="d-flex align-items-center flex-wrap w-100 gap-1">
                            <div class="d-flex align-items-center flex-wrap gap-1" v-if="this.hasTablePermission">
                                <p class="mb-0 f-14 font-600">Search Parameters</p>
                                <div style="width: 200px;">
                                    <multiselect
                                        v-model="searchParam"
                                        :options="[
                                            { value: 'bookingNumber', label: 'Booking ID' },
                                            { value: 'userName', label: 'User Name' },
                                            { value: 'mobileNumber', label: 'Phone Number' },
                                            { value: 'email', label: 'Email' },
                                        ]"
                                        label="label"
                                        track-by="value"
                                        :show-labels="false"
                                        :multiple="false"
                                        :searchable="false"
                                        :allow-empty="false"
                                        @input="handleChangeSearchParam()"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                            <div class="input-group" style="flex: 0 0 220px;" v-if="this.hasTablePermission">
                                <span class="input-group-prepend">
                                    <div class="input-group-text border-right-0 bg-transparent text-blue">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span>
                                <input
                                    class="form-control border-left-0"
                                    v-model="search"
                                    @input="handleSearch"
                                    type="search"
                                    placeholder="Search appointment..."
                                >
                            </div>
                            <div class="btn-responsive" v-if="hasAddPermission">
                                <router-link to="/offline-clinic-booking/user" class="btn btn-primary rounded">
                                    Create New Appointment
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-1 px-3" v-if="this.hasTablePermission">
                        <div class="d-flex align-items-center flex-wrap w-100 gap-1">
                            <div class="d-flex align-items-center flex-wrap gap-1">
                                <p class="mb-0 f-14 font-600">Clinic Name</p>
                                <div style="width: 200px;">
                                    <multiselect
                                        v-model="clinic"
                                        placeholder="Select Clinic"
                                        :options="clinicNameOptions"
                                        label="name"
                                        track-by="id"
                                        select-label=""
                                        deselect-label="Reset"
                                        :multiple="false"
                                        @input="getList();"
                                    >
                                        <span slot="noResult">Oops! No data found.</span>
                                    </multiselect>
                                </div>
                            </div>
                            <div class="d-flex align-items-center flex-wrap gap-1">
                                <p class="mb-0 f-14 font-600">Date</p>
                                <div style="width: 240px;" id="datepicker">
                                    <date-picker
                                        v-model="dateRange"
                                        format="DD/MM/YYYY"
                                        type="date"
                                        input-class="form-control"
                                        value-type="YYYY-MM-DD"
                                        placeholder="Select Date"
                                        style="width: 100%;"
                                        range
                                        :disabled-date="disabledDate"
                                        @input="getList();"
                                    >
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="this.hasTablePermission" class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="font-600 text-black text-left">Booking ID</th>
                                    <th class="font-600 text-black text-left">Treatment Date</th>
                                    <th class="font-600 text-black text-left">Treatment Time</th>
                                    <th class="font-600 text-black text-left">Status</th>
                                    <th class="font-600 text-black text-left">User Name</th>
                                    <th class="font-600 text-black text-left">Phone Number</th>
                                    <th class="font-600 text-black text-left">Clinic Name</th>
                                    <th class="font-600 text-black text-left">Treatment Detail</th>
                                    <th class="font-600 text-black text-left" v-if="hasEditPermission">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(booking, bookingKey) in results.rows" :key="bookingKey">
                                    <td class="text-left align-top">
                                        <router-link
                                            :to="`/offline-clinic-booking/${booking.bookingNumber}`"
                                            class="text-light-blue font-500"
                                        >
                                            {{ booking.bookingNumber }}
                                        </router-link>
                                    </td>
                                    <td class="text-left align-top">
                                        {{ booking.date | moment("DD MMMM YYYY") }}
                                    </td>
                                    <td class="text-left align-top">
                                        {{ booking.startTime }} - {{ booking.endTime }}
                                    </td>
                                    <td class="text-left align-top">
                                        {{ parseBookingStatus(booking.status)  }}
                                    </td>
                                    <td class="text-left align-top">
                                        {{ booking.user.name }}
                                        <span
                                            class="text-warning f-12"
                                            v-if="!booking.user.hasTreatmentHistory"
                                            style="vertical-align: top;"
                                        >
                                            new
                                        </span>
                                    </td>
                                    <td class="text-left align-top">
                                        {{ booking.user.mobileNumber }}
                                    </td>
                                    <td class="text-left align-top">
                                        {{ booking.clinicName }}
                                    </td>
                                    <td class="text-left align-top">
                                        <p v-for="(treatment, treatmentKey) in booking.treatmentTitles" :key="treatmentKey">
                                            {{ treatment }}
                                        </p>
                                    </td>
                                    <td class="text-left align-top" v-if="hasEditPermission">
                                        <button
                                            :class="`btn ${generateButtonAction(booking.status).class}`"
                                        >
                                            {{ generateButtonAction(booking.status).label }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center mt-1" v-if="hasTablePermission">
                        <p class="f-14" v-if="!results.rows.length">There is no data</p>
                    </div>
                </div>
            </div>
            <div class="card" v-if="hasTablePermission">
                <div class="card-body d-flex flex-row">
                    <PaginateList :data="results" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PaginateList from '@/components/PaginateList';
    import { debounce } from "debounce";

    export default {
    	components: {
      		PaginateList,
    	},
        data() {
            return {
                results: {
                    rows: [],
                    currentPage: 1,
                    totalPages: 1,
                },
                limit: 10,
                // filter
                status: null,
                searchParam: { value: 'bookingNumber', label: 'Booking ID' },
                search: '',
				clinic: null,
				clinicNameOptions: [],
                dateRange: [null, null],
            }
        },
        computed: {
            permissions() {
                return this.$store.getters.permissions;
            },
            hasViewPermission() {
                return this.permissions.includes('offline_clinic_booking:view');
            },
            hasEditPermission() {
                return this.permissions.includes('offline_clinic_booking:edit');
            },
            hasAddPermission() {
                return this.permissions.includes('offline_clinic_booking:add');
            },
            hasTablePermission() {
              return this.hasViewPermission || this.hasEditPermission;
            },
            sanitizedSearch() {
                if (this.searchParam.value === 'email') {
                    return this.search.replace(/ /g, '');
                }
                if (this.searchParam.value === 'mobileNumber') {
                    const trimmedValue = this.search.replace(/ /g, '');
                    if (trimmedValue.startsWith('+62')) {
                        return `0${trimmedValue.slice(3)}`;
                    }
                    return trimmedValue;
                }
                if (this.searchParam.value === 'bookingNumber') {
                    return this.search.toUpperCase();
                }
                return this.search;
            },
        },
        mounted() {
            if (this.hasTablePermission) {
                Promise.all([
                    this.getList(),
                    this.getClinicNameOptions(),
                ]);
                this.$root.$on('paginate', (data) => {
                    this.getList(data);
                });
            }
        },
        methods: {
            handleChangeStatus(newStatus) {
                this.status = newStatus;
                this.getList();
            },
            handleChangeSearchParam() {
                this.getList();
            },
            handleSearch: debounce(function() {
                this.getList();
            }, 1000),
            resetFilter() {
                this.status = null;
                this.search = '';
                this.searchParam = { value: 'bookingNumber', label: 'Booking ID' };
                this.clinic = null;
                this.dateRange = [null, null];
                this.getList();
            },
            getList(page = 1) {
                const isValidDateRange = this.dateRange[0] && this.dateRange[1];
                const params = {
                    page,
                    limit: this.limit,
                    ...(this.status && { status: this.status }),
                    ...(this.sanitizedSearch && { [this.searchParam.value]: this.sanitizedSearch }),
                    ...(this.clinic && { clinicId: this.clinic.id }),
                    ...(isValidDateRange && { date: `betweenDate: ${this.dateRange[0]},${this.dateRange[1]}` }),
                };

                this.axios.get('/v2/clinical/offline-clinic-booking', { params })
                    .then((res) =>{
                        this.results = res.data.data;
                    })
            },
			getClinicNameOptions() {
				this.axios
					.get('/v2/misc/offline-clinics?isActive=true')
					.then((res) => {
						this.clinicNameOptions = res.data.data.rows;
					})
					.catch((err) => {
						this.$notify({
							type: 'error',
							title: 'Error',
							text: err.response.data.message,
						});
				});
			},
            disabledDate(date, currentValue) {
                if (currentValue[0] && currentValue[1]) return false;

                const maxThirtyOneDaysFuture =
                    currentValue[0] && this.$moment(date).isAfter(currentValue[0], 'day')
                        ? date > new Date(currentValue[0].getTime() + 31 * 24 * 3600 * 1000)
                        : false;
                const maxThirtyOneDaysPast =
                    currentValue[0] && this.$moment(date).isBefore(currentValue[0], 'day')
                        ? date < new Date(currentValue[0].getTime() - 31 * 24 * 3600 * 1000)
                        : false;
                return maxThirtyOneDaysFuture || maxThirtyOneDaysPast;
            },
            parseBookingStatus(statusCode) {
                return statusCode
                    .split('_')
                    .reduce((acc, curr) => ( `${acc} ${curr.charAt(0).toUpperCase()}${curr.slice(1).toLowerCase()}` ), '');
            },
            generateButtonAction(statusCode) {
                switch(statusCode) {
                    case 'BOOKED':
                        return {
                            label: 'Confirm',
                            class: 'btn-primary',
                            onClick: () => {},
                        }
                    case 'CONFIRMED':
                        return {
                            label: 'Check-in',
                            class: 'btn-primary',
                            onClick: () => {},
                        }
                    case 'CHECKED_IN':
                        return {
                            label: 'Mark as on treatment',
                            class: 'btn-primary',
                            onClick: () => {},
                        }
                    case 'ON_TREATMENT':
                        return {
                            label: 'Complete',
                            class: 'btn-primary',
                            onClick: () => {},
                        }
                    default:
                        return {
                            label: 'See Detail',
                            class: 'btn-outline-primary',
                            onClick: () => {},
                        }
                }
            }
        },
    }
</script>

<style scoped>
    .gap-1 {
        gap: 1rem;
    }
</style>

<style>
    #datepicker .form-control {
        padding: 20px 10px;
    }
</style>